export default function () {
  const structuredData = computed(() => {
    return {
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@type': 'WebSite',
          name: 'TAFE NSW',
          url: 'https://www.tafensw.edu.au',
          potentialAction: {
            '@type': 'SearchAction',
            target:
              'https://www.tafensw.edu.au/course-search?keyword={search_term_string}',
            'query-input': 'required name=search_term_string'
          }
        },
        {
          '@type': 'Organization',
          name: 'TAFE NSW',
          url: 'https://www.tafensw.edu.au/',
          logo: 'https://www.tafensw.edu.au/TAFE-logo.svg',
          description:
            'TAFE NSW offers hundreds of courses with a wide range of study options from online courses to diplomas and degrees, training and full-time education.',
          sameAs: [
            'https://www.facebook.com/TAFENSW/',
            'https://x.com/tafensw',
            'https://www.instagram.com/tafensw/',
            'https://www.youtube.com/tafe',
            'https://www.linkedin.com/school/tafe-nsw/',
            'https://www.tafensw.edu.au/'
          ]
        }
      ]
    }
  })

  return { structuredData }
}
